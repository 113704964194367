<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="is-scroll">
        <table class="table is-fullwidth">
          <thead class="is-sticky" v-if="hasOptions">
            <tr>
              <th>Code</th>
              <th>Description</th>
              <th>
                <TableSearch @change="search" placeholder="Search" size="6" />
              </th>
            </tr>
          </thead>
          <tbody v-if="options.length" class="is-inverse-stripey">
            <tr v-for="(option, index) in options" :key="index">
              <td>
                <p class="has-text-weight-semibold">{{ option.code || '-' }}</p>
              </td>
              <td colspan="2">{{ option.description }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!options.length || !hasOptions">
            <tr>
              <td colspan="3">
                <TableEmpty size="small" :text="noOptionsText" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column">
      <div class="columns is-vcentered">
        <div class="column">
          <a
            class="help is-valigned has-text-right has-text-info"
            @click="$emit('open-contact-form')"
          >
            <span class="icon is-small is-valigned">
              <i class="fal fa-question-circle" />
            </span>
            <span class="is-valigned">Enquire about this data</span>
          </a>
        </div>
        <div v-if="allowReCheck" class="column is-narrow">
          <button class="button is-rounded is-info" @click="getOptions">
            Check again
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateIsBefore, addTime } from '@/utils/date.utils.ts'

export default {
  name: 'CompanionOptionsList',
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    hasOptions: {
      type: Boolean,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: () => null
    }
  },
  components: {
    TableEmpty: () => import('core/components/TableEmpty'),
    TableSearch: () =>
      import('core/modules/table-search/components/TableSearch')
  },
  computed: {
    allowReCheck() {
      const { hasOptions, createdAt } = this
      const checkedInLastHour = dateIsBefore(
        Date.now(),
        addTime(createdAt, { hours: 1 })
      )
      return !hasOptions && !checkedInLastHour
    },
    noOptionsText() {
      return this.hasOptions
        ? 'No options corresponding to your search'
        : 'No options available for this vehicle'
    }
  },
  methods: {
    search(value) {
      this.$emit('search', value || '')
    },
    getOptions() {
      this.$mxp.track('companion_options_re-request')
      this.$emit('get-options')
    }
  }
}
</script>
<style lang="sass" scoped>
.is-scroll
  max-height: 50vh
  overflow: auto
.is-sticky
    position: sticky
    top: 0
    background-color: #fff
    th
      vertical-align: middle
</style>
